<template>
  <div>
    <validation-observer ref="simple">
      <b-form>
        <b-card-code title="تعديل تصنيف">
          <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-row>
            <b-col md="3" xl="3">
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="title"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> الاسم</label>
                <b-form-input v-model="name" type="text" id="input-title" />
              </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          
            <b-col md="3" xl="3" >
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="code"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> الكود</label>
                <b-form-input v-model="code" type="text" id="input-default" />
              </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="3" xl="3" >
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="en_name"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> الاسم باللغة الانجليزية</label>
                <b-form-input v-model="en_name" type="text" id="input-default" />
              </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
           
            <b-col md="1" xl="3">
              <b-button variant="purple" @click="editItemCategory">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle"> تعديل التصنيف</span>
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
        </b-card-code>
      </b-form>
    </validation-observer>
    <b-col md="4" xl="2">
      <b-button variant="purple " @click="$router.go(-1)">
        <span class="align-middle">رجوع</span>
      </b-button>
    </b-col>
  </div>
</template>

<script>

import { required, max, is_earlier } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";

import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,

} from "bootstrap-vue";




import {

  BFormInput,
 
  BFormFile,
  BButton,
  BForm,
  BFormInvalidFeedback,
} from "bootstrap-vue";
export default {


  components: {
    required,
 
    BFormInvalidFeedback,

    BForm,
    ValidationObserver,
    ValidationProvider,

    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
 
   
    BRow,

    BCol,
  },

  data() {
    return {
      name: "",
      code:'',

      en_name: "",
      
 
   
      id: "",
   
    };
  },

  created() {
    this.getItemCategory()

  },

  methods: {
    
    editItemCategory() {
      return new Promise((resolve, reject) => {
        this.$refs.simple
          .validate()
          .then((success) => {
            if (success) {
              let data =[]
            
                data = {
        en_name: this.en_name,
        code: this.code,
        name:this.name,
      };
          
      this.$http.put(`/api/v1/item-category/${this.$route.params.id}`,data).then((res) => {
        //console.log(res);
       
        this.$swal({
          title: "",
          text: "تمت تعديل التصنيف بنجاح",
          icon: "success",
          confirmButtonText: "موافق",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
       
      });
    }})})
    },
    

    getItemCategory() {
      this.$http.get(`/api/v1/item-category/${this.$route.params.id}`).then((res) => {
        //console.log('rewards', res)
    this.en_name=res.data.data.en_name
         this.code=res.data.data.code
     this.name=res.data.data.name
      });
    },

    //
  },
};
</script> 

<style scoped>
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}

span {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.align-left {
  left: 50px;
}
.but {
  top: 40px;
}
</style>